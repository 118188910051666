import React, { Component } from "react";
import { Query } from 'react-apollo';
import HEADER_QUERY from '../api/header';
import Typical from "react-typical";
import Switch from "react-switch";
import Lottie from "lottie-react";
import development from "./development.json";

class Header extends Component {
  titles = [];

  constructor() {
    super();
    this.state = { checked: false };
    this.onThemeSwitchChange = this.onThemeSwitchChange.bind(this);
  }

  onThemeSwitchChange(checked) {
    this.setState({ checked });
    this.setTheme();
  }

  setTheme() {
    var dataThemeAttribute = "data-theme";
    var body = document.body;
    var newTheme = body.getAttribute(dataThemeAttribute) === "dark" ? "light" : "dark";
    body.setAttribute(dataThemeAttribute, newTheme);
  }


  render() {
    if (this.props.sharedData) {
      this.titles = this.props.sharedData.titles.map(x => [ x.toUpperCase(), 1500 ] ).flat();
    }

    const HeaderTitleTypeAnimation = React.memo( () => {
      return <Typical className="title-styles" steps={this.titles} loop={50} />
    }, (props, prevProp) => true);

    return (
      <header id="home" style={{ height: window.innerHeight, display: 'block', position: 'relative' }}>
        <div className="row aligner" style={{height: '100%'}}>
          <div className="col-md-12">
            <div>
              <Lottie  className="lottie-icon" loop autoplay animationData={development} />
              <br/>
              <h1 className="mb-0">
              <Query query={HEADER_QUERY}>
                {({ loading, error, data }) => {
                    if (loading) return <div></div>
                    if (error)   return <div></div>
                    const name = data.headers.map(x => [ x.typeIt ] ).flat();
                    return (
                      <div>
                        <Typical steps={[name[0]]} wrapper="p" />
                      </div>
                    )
                }}
              </Query>
              </h1>
              <div className="title-container">
                <HeaderTitleTypeAnimation loop={50} />
              </div>
              <Switch
                checked={this.state.checked}
                onChange={this.onThemeSwitchChange}
                offColor="#baaa80"
                onColor="#353535"
                className="react-switch mx-auto"
                width={42}
                height={20}
                uncheckedIcon={
                  <span
                    className="iconify"
                    data-icon="noto-v1:first-quarter-moon-face"
                    data-inline="false"
                    style={{
                      display: "block",
                      height: "100%",
                      fontSize: 15,
                      textAlign: "end",
                      marginLeft: "5px",
                      color: "#353239",
                      cursor: 'pointer'
                    }}
                  ></span>
                }
                checkedIcon={
                  <span
                    className="iconify"
                    data-icon="noto-v1:sunflower"
                    data-inline="false"
                    style={{
                      display: "block",
                      height: "100%",
                      fontSize: 15,
                      textAlign: "end",
                      marginLeft: "5px",
                      color: "#353239",
                      cursor: 'pointer'
                    }}
                  ></span>
                }
                id="icon-switch"
              />
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
