import gql from "graphql-tag";
const PAGES_QUERY = gql`
query {
    pages {
      body {
        html
      }
      title
    }
  }
`;
export default PAGES_QUERY;