import React, { Component } from "react";
import { Icon } from "@iconify/react";
import { Query } from 'react-apollo';
import PAGES_QUERY from '../api/pages';
import javascriptIcon from "@iconify/icons-logos/javascript";
import reactIcon from "@iconify/icons-logos/react";
import electronIcon from "@iconify/icons-logos/electron";
import androidIcon from "@iconify/icons-logos/android-icon";
import appleIcon from "@iconify/icons-logos/apple";

class About extends Component {
  render() {
    if (this.props.sharedBasicInfo) {
      var profilepic = "images/" + this.props.sharedBasicInfo.image;
    }
    if (this.props.resumeBasicInfo) {
      var sectionName = this.props.resumeBasicInfo.section_name.about;
    }

    return (
      <section id="about">
        <div className="col-md-12">
          <h1 style={{ color: "black", margin: "0 0 5rem 0" }}>
            <span>{sectionName}</span>
          </h1>
          <div className="row center mx-auto mb-5">
            <div className="col-md-4 mb-5 center">
              <div className="polaroid">
                <span style={{ cursor: "auto" }}>
                  <img
                    height="auto"
                    width="auto"
                    src={profilepic}
                    alt="Avatar placeholder"
                  />
                  <Icon
                    icon={reactIcon}
                    style={{ fontSize: "400%", margin: "9% 5% 0 5%" }}
                  />
                  <Icon
                    icon={javascriptIcon}
                    style={{ fontSize: "400%", margin: "9% 5% 0 5%" }}
                  />
                  <Icon
                    icon={electronIcon}
                    style={{ fontSize: "400%", margin: "9% 5% 0 5%" }}
                  />
                  <Icon
                    icon={androidIcon}
                    style={{ fontSize: "400%", margin: "9% 5% 0 5%" }}
                  />
                  <Icon
                    icon={appleIcon}
                    style={{ fontSize: "400%", margin: "9% 5% 0 5%" }}
                  />
                </span>
              </div>
            </div>

            <div className="col-md-8 center">
              <div>
                <div className="card">
                  <div className="card-header">
                    <span
                      className="iconify"
                      data-icon="emojione:red-circle"
                      data-inline="false"
                    ></span>{" "}
                    &nbsp;{" "}
                    <span
                      className="iconify"
                      data-icon="twemoji:yellow-circle"
                      data-inline="false"
                    ></span>{" "}
                    &nbsp;{" "}
                    <span
                      className="iconify"
                      data-icon="twemoji:green-circle"
                      data-inline="false"
                    ></span>
                  </div>
                  <div
                    className="card-body font-trebuchet ml-3 mr-3 row"
                    style={{
                      height: "auto",
                      fontSize: "132%",
                      lineHeight: "200%",
                    }}
                  >
                    <div className="col-md-8">
                      <Query query={PAGES_QUERY}>
                        {({ loading, error, data }) => {
                          if (loading) return <div></div>
                          if (error) return <div></div>
                          const page = data.pages[0];
                          return (
                            <div>
                              <h2 className="wave" style={{ margin: '3rem 0' }}>{page.title}</h2>
                              <div dangerouslySetInnerHTML={{ __html: page.body.html }} />
                            </div>
                          )
                        }}
                      </Query>
                      <hr style={{ margin: '3rem 0 0 0' }} />
                      <p style={{ margin: '3rem 0 1rem 0' }}><strong>Strengths</strong></p>
                      <p className="main-badge mr-2 mb-2 font-weight-bold badge badge-pill">Good communication skills</p>
                      <p className="main-badge mr-2 mb-2 font-weight-bold badge badge-pill">I try to learn something new every day</p>
                      <p className="main-badge mr-2 mb-2 font-weight-bold badge badge-pill">Keen to try new things</p>
                      <p className="main-badge mr-2 mb-2 font-weight-bold badge badge-pill">High-performing team member</p>
                      <p className="main-badge mr-2 mb-2 font-weight-bold badge badge-pill">Agile continuous improvement</p>
                    </div>
                    <div className="col-md-1 top"></div>
                    <div className="col-md-3 top">
                    <p style={{ margin: '3rem 0 1rem 0' }}><strong>Contact details</strong></p>
                      <p><a className="call-badge mr-2 mb-3 badge badge-pill" title="Email me any questions" href="mailto:alex@frithir.com"><span role="img" aria-label="email">📧</span> alex@frithir.com</a>
                      <a className="call-badge mr-2 badge badge-pill" title="Give me a buzz to discuss" href="tel:0421834334"><span role="img" aria-label="phone">📱</span> 0421 834 334</a></p>
                      <hr style={{ margin: '3rem 0' }} />
                      <p><strong>References</strong></p>
                      <p>Dean Oakley<br />
                      Director at Thrive Web <br />
                        0432 958 299</p>
                      <p>Chris Clark <br />
                        Director at Thrieen Digital <br />
                        0418 290 875</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default About;
